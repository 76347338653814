<template>
    <r-e-dialog :title="type === 'add' ? '添加房源' : '编辑房源'" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" top="10vh"
                @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
        <el-form ref="formPublish" label-width="100px" size="small" :model="formPublish" :rules="rules">
            <div class="flex">
                <el-form-item label="行政区" prop="district">
                    <el-autocomplete v-model="formPublish.district" :fetch-suggestions="districtQuerySearch"
                                     placeholder="请输入内容"/>
                </el-form-item>
                <el-form-item label="区域地址" prop="addressDistrict">
                    <el-autocomplete v-model="formPublish.addressDistrict" :fetch-suggestions="addressDistrictQuerySearch"
                                     placeholder="请输入内容"/>
                </el-form-item>
                <el-form-item label="房屋地址" prop="address">
                    <el-input v-model="formPublish.address" placeholder="请输入内容"/>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="面积" prop="area">
                    <el-input v-model="formPublish.area" placeholder="请输入内容" style="width: 200px;"/>
                </el-form-item>
                <el-form-item label="备注" style="width: 500px;">
                    <el-input type="textarea" :rows="3" v-model="formPublish.comment" style="width: 500px;"/>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
import { getDistrictList, getAddressDistrictList, readyApartmentSave, readyApartmentUpdate } from '@/api/existing-listings';
import { MessageSuccess } from "@custom/message";
export default {
    name: "layer-save-existing-listings",
    data() {
        return {
            dialogVisible: false,
            type: "",
            formPublish: {
                uuid: null,
                district: null,
                addressDistrict: null,
                address: null,
                area: null,
                comment: null,
            },
            rules: {
                district: [{ required: true, message: '请输入行政区', trigger: 'blur' }],
                addressDistrict: [{ required: true, message: '请输入区域地址', trigger: 'blur' }],
                address: [{ required: true, message: '请输入房屋地址', trigger: 'blur' }],
                area: [{ required: true, message: '请输入面积', trigger: 'blur' }],
            },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
            districtList: [],
            addressDistrictList: [],
        };
    },
    props: {},
    components: {},
    methods:{
        openDialog(type, data) {
            this.type = type;
            this.dialogVisible = true;
            if(type === 'exit'){
                const oldData = this.formPublish;
                this.formPublish = { ...oldData, ...data };
            }
        },

        clickSubmit() {
            let that = this;
            this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        const {type} = this;
                        const {uuid, district, addressDistrict, address, area, comment} = this.formPublish;
                        const data = {district, addressDistrict, address, area, comment};

                        if(type === 'add') data.uuid = uuid;

                        if(type === 'add') that.readyApartmentSave(data);
                        else that.readyApartmentUpdate(data)
                    }
            });
        },

        clickCancel() {
            this.formPublish = {
                uuid: null,
                district: null,
                addressDistrict: null,
                address: null,
                area: null,
                comment: null,
            };
            this.dialogVisible = false;
        },

        readyApartmentSave(data){
            let that = this;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            readyApartmentSave(data).then(res=>{
                MessageSuccess("添加成功");
                that.clickCancel();
            }).finally(() => loading.close());
        },

        readyApartmentUpdate(data){
            let that = this;
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            readyApartmentUpdate(data).then(res=>{
                MessageSuccess("修改成功");
                that.clickCancel();
            }).finally(() => loading.close());
        },


        districtQuerySearch(queryString, cb) {
            const restaurants = this.districtList;
            const results = queryString ? restaurants.filter(({value}) => value.includes(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },

        addressDistrictQuerySearch(queryString, cb) {
            const restaurants = this.addressDistrictList;
            const results = queryString ? restaurants.filter(({value}) => value.includes(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
    },
    async mounted() {
        const {list:districtList} = await getDistrictList();
        const {list:addressDistrictList} = await getAddressDistrictList();
        this.districtList = districtList.map(v => {
            return { value:v }
        });
        this.addressDistrictList = addressDistrictList.map(v => {
            return { value:v }
        });
    },
}
</script>

<style scoped>

</style>